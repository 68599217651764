<template>
  <mobile-screen :header="true" screen-class="profile-screen icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="applications-header icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("applications", "applications", "applications") }}
        </div>
      </top-header-menu-wrapper>
      <section class="search-wrapper applications-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('applications', 'applications', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul
      class="named-list"
      v-if="listOfApplications && listOfApplications.length"
    >
      <li class="name-wrapper">
        <p class="tiny-label bg">
          {{ displayLabelName("applications", "applications", "applications") }}
        </p>
        <ul class="bordered-list">
          <li class="list-item" v-for="app in listOfApplications" :key="app.id">
            <div class="checkbox tiny block">
              <input
                type="radio"
                :id="`user${app.id}`"
                name="user"
                :selected="app.id === selectedApp"
                :value="app.id"
                @change="selectApp(app.id)"
              />
              <label :for="`user${app.id}`">
                <icon icon="#cx-app1-checkmark" />
                {{ app.name }}</label
              >
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :disabled="!selectedApp || processing"
              @click="editApp"
            >
              {{ displayLabelName("applications", "applications", "select") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </mobile-screen>
</template>

<script>
import { mapActions, mapState } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";

export default {
  name: "ApplicationsSelection",
  data() {
    return {
      selectedApp: 0,
      processing: false,
      show: false,
      searchQuery: ""
    };
  },
  created() {
    if (!this.applications) this.getApplications();
  },
  computed: {
    ...mapState("applications", ["applications"]),
    listOfApplications() {
      const query = this.searchQuery;
      if (
        this.applications &&
        this.applications.data &&
        this.applications.data.length
      ) {
        if (query && query.length > 1) {
          return this.applications.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.applications.data;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("applications", [
      "getApplications",
      "setSelectedApplication"
    ]),
    selectApp(id) {
      this.selectedApp = id;
    },
    editApp() {
      this.setSelectedApplication(
        this.applications.data.filter(item => item.id === this.selectedApp)
      );
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
